import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import Cognito from '../../components/Cognito'



export default class Index extends React.Component {
  

  render() {
    return (
      <Layout>
        <Cognito />
      </Layout>
    )
  }
}
