import React from 'react';
const classname = "cognito-contain";
const content = `
<iframe src="https://services.cognitoforms.com/f/C5yLSK2CLEW86fQtUdj0yQ?id=1" style="position:relative;width:1px;min-width:100%;*width:100%;" frameborder="0" scrolling="yes" seamless="seamless" height="523" width="100%"></iframe>
<script src="https://services.cognitoforms.com/scripts/embed.js"></script>    `

export const Cognito = () => (

    
    

    <div className={classname} dangerouslySetInnerHTML={{ __html: content }} />
  )

  export default Cognito